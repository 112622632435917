import * as React from 'react';
import { PageProps, withPageProps } from '../components/Page'
import * as Styles from '@material-ui/core/styles';
import * as Model from '../models'
import * as HokanApi from '../api/hokan'
import Loading from '../components/Loading'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import SubTitle from '../components/SubTitle'
import * as Colors from '../components/Colors'
import { withStyles } from '@material-ui/core/styles';
import { Patient } from '../models/Patient'
import * as Icons from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { Paper, Tabs, Tab, Select, MenuItem, Grid } from '@material-ui/core';
import DateUtil from '../util/DateUtil'
import StandardTable from '../components/StandardTable';
import Col2Table from '../components/Col2Table';
import TalkList from '../components/TalkList';
import { StoreAccessor } from '../util/StoreUtil';
import Firebase from '../firebase'
import { saveAs } from 'file-saver';
import Encoding from 'encoding-japanese';

const styles = () => Styles.createStyles({
    root: {
        margin: "10px",
        maxWidth: "2000px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px"
    },
    table: {
        marginBottom: "20px",
        color: Colors.primaryColor,
        fontSize: "0.9rem",
        transition: "linear 0.2s",
    },
    tableHead: {
        background: Colors.primaryColor,

    },
    row: {
        border: "solid",
        marginTop: "-1px",
        borderColor: "#e0e0e0",
        borderWidth: "1px",
        borderLeft: "none",
        borderRight: "none",
        cursor: "pointer",
        '&:nth-of-type(odd)': {
            backgroundColor: Colors.backColor
        },
        "&:last-child": {


        },
        "&:hover": {
            backgroundColor: Colors.borderColorTransparent,
            outlineStyle: "solid",
            outlineWidth: "1px",
            outlineColor: Colors.primaryColor,
            border: "none",

        }
    },
    itemIcon: {
        "& svg": {
            fontSize: "15px"
        }
    }
})

const CustomTableCell = withStyles(theme => ({
    head: {
        fontSize: "0.9rem",
        backgroundColor: Colors.primaryColor,
        color: Colors.white,
        "&:last-child": {

        },
        "&:first-child": {

        },
    },
    body: {
        fontSize: "0.9rem",
    },
}))(TableCell);

interface Props extends PageProps, Styles.WithStyles {
}

interface LocalState {
    branches: Model.Branch[]
    patients: Model.Patient.Patient[]
    patientsJimu: Model.Patient.Patient[]
    query: Query
    loading: boolean
    loadingJimu: boolean
    openAddMenu: boolean
    tabIndex: number
    branch_id: number | null
    me: Model.User,
    favoList: any[]
}

class Query {
    name: string = ""
    onlyMe: boolean = false
    showSaigai: boolean = false
    showDisabled: boolean = false
    showCreatedAt: boolean = false;
    sortKey: string = "name_kana"
    sortDir: number = 1
}

class PatientListPage extends React.Component<Props, LocalState> {
    displayName = "PatientListPage"
    constructor(props: Props) {
        super(props)
        let index = 0;
        if (props.location.hash == "#new") {
            index = 1
        }
        else if (props.location.hash == "#thread") {
            index = 3
        }


        this.state = {
            query: new Query(),
            patients: [],
            patientsJimu: [],
            loading: true,
            loadingJimu: false,
            openAddMenu: false,
            tabIndex: index,
            branches: [],
            branch_id: null,
            me: new Model.User(),
            favoList: []
        }
    }

    public componentDidMount = () => {
        const qls = localStorage.getItem("patients_query")
        const q = this.state.query
        let bid: number | null = null
        if (qls) {
            const jps = JSON.parse(qls)
            if (jps.query) {
                const lq = jps.query
                q.name = lq.name || ""
                q.onlyMe = lq.onlyMe || false
                q.showSaigai = lq.showSaigai || false
                q.showDisabled = lq.showDisabled || false
                q.showCreatedAt = lq.showCreatedAt || false
                q.sortKey = lq.sortKey || "name_kana"
                q.sortDir = lq.sortDir || 1
            }
            if (jps.branch_id) {
                bid = jps.branch_id
            }
        }

        HokanApi.message.mention_list()
            .then( x => {
            })

        HokanApi.branch.list()
            .then(x => {
                return x.data
            })
            .then(branch_list => {
                HokanApi.me().then(x => {
                    const b = bid && branch_list.findIndex(b => b.id == bid) >= 0 ? bid : x.data.branch_id
                    const me = x.data
                    this.setState({ branch_id: b, me: me, branches: branch_list.concat({ id: 0, name: "全事業所" } as Model.Branch), query: q })
                    this.loadBranch(b)
                    if (me.branch) {
                        Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).get().then((doc) => {
                            const d = doc ? doc.data() : {}
                            this.setState({ favoList: (d ? d.favo : []) })
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                })
                    .catch(err => {
                        console.log(err)
                    })
            })
            .catch(err => {
                console.log(err)
            })
    }

    private loadBranch = (branchId) => {
        this.setState({
            loading: true,
            branch_id: branchId,
        })
        HokanApi.getPatients(branchId)
            .then(async x => {
                this.setState({
                    patients: x.data.map(y => Model.Patient.Patient.load(y, false)),
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({ loading: false })
            })
    }

    public tabChange(event, tabIndex) {
        if (tabIndex == 0) {
            this.props.history.push("/patients")
        }
        else if (tabIndex == 1) {
            this.props.history.push("/patients#new")
        }
        else if (tabIndex == 3) {
            this.props.history.push("/patients#thread")
        }

        this.setState({ tabIndex: tabIndex })
        if (tabIndex == 2 && this.state.loadingJimu == false) {
            this.setState({ loadingJimu: true })
            HokanApi.getPatientsJimu(this.state.branch_id)
                .then(async x => {
                    this.setState({
                        patientsJimu: x.data.map(y => Model.Patient.Patient.load(y, false)),
                        loadingJimu: false
                    })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ loadingJimu: false })
                })
        }
    }

    private setSortKey(key: string) {
        const q = this.state.query
        const dir = (q.sortKey == key ? q.sortDir * -1 : 1)
        q.sortKey = key
        q.sortDir = dir
        this.setState({ query: q })
    }

    private setFavo(pid: number, name: string, f: boolean) {
        const me = this.state.me
        if (!me || !me.branch || !me.id) return
        if (f) {
            const newList = (this.state.favoList || []).concat([{ id: pid, name: name }])
            this.setState({ favoList: newList })
            Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).set({
                favo: newList
            }).catch(err => {
                console.log(err)
            })

            HokanApi.message.favorite_wc(pid).then(x => {
            })
        }
        else {
            const newList = (this.state.favoList || []).filter(x => x.id != pid)
            this.setState({ favoList: newList })
            Firebase.instance.store.collection(`company/${me.branch.company_id}/user`).doc(`${me.id}`).set({
                favo: newList
            }).catch(err => {
                console.log(err)
            })

            HokanApi.message.delete_favorite_wc(pid).then(x => {
            })
        }
    }
    private isFavo(pid: number) {
        return this.state.favoList.findIndex(x => x.id == pid) >= 0
    }

    public render() {
        const { classes, history } = this.props;
        const { query, loading, tabIndex } = this.state;
        const saQ = new StoreAccessor(this, true, "query")

        if (!this.state.loading) {
            const query_item = JSON.stringify({
                query: query,
                branch_id: this.state.branch_id
            })
            localStorage.setItem("patients_query", query_item)
        }

        const element_l = [
            {
                name: "", content: <div>
                    <Select
                        value={this.state.branch_id || '0'}
                        autoWidth={true}
                        variant='outlined'
                        onChange={(e) => this.loadBranch(e.target.value)}
                        className={classes.input}
                    >
                        {
                            this.state.branches.map((t, idx) =>
                                <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                            )
                        }
                    </Select>
                </div>
            },
            { name: "", content: saQ.text("name", "名前、電話番号、担当者名、住所", "text", "block", false) },
            {
                name: "", content: saQ.checkList(
                    [{ label: "自担当", key: "onlyMe" },
                    { label: "利用中以外", key: "showDisabled" },
                    { label: "災害時優先度", key: "showSaigai" },
                    { label: "登録日", key: "showCreatedAt" }
                    ])
            }
        ]

        saQ.callBack = (cat, name, val) => {
            if (name == "showSaigai" && val == true) {
                this.setSortKey("saigai");
            }
        }

        let notMatch = (filter: string, value: string) => {
            if (filter == "") return false;
            return value.indexOf(filter) == -1
        }
        function kanaToHira(str: string) {
            if (!str) return ""
            return str.replace(/[\u30a1-\u30f6]/g, function(match) {
                var chr = match.charCodeAt(0) - 0x60;
                return String.fromCharCode(chr);
            });
        }
        let notNameMatch = (filter: string, value: string, kana: string) => {
            if (value) {
                return notMatch(filter, value) &&
                    notMatch(kanaToHira(filter), kanaToHira(kana));
            }
            return filter == "" ? false : true;
        }
        let notAddMatch = (filter: string, value: string) => {
            if (value) {
                return notMatch(filter, value)
            }
            return filter == "" ? false : true;
        }
        let notTelMatch = (filter: string, value: string) => {
            if (value) {
                return notMatch((filter || "").replace(/\-/g, ""), (value || "").replace(/\-/g, ""))
            }
            return filter == "" ? false : true;
        }
        let notTantoMatch = (filter: string, pat: Model.Patient.Patient) => {
            if (filter == "") return false
            return (!pat.main_user || notMatch(filter, pat.main_user.name))
                && (!pat.sub_user1 || notMatch(filter, pat.sub_user1.name))
                && (!pat.sub_user2 || notMatch(filter, pat.sub_user2.name))
        }

        let getSortValue = (pat: Model.Patient.Patient, key: string) => {
            if (key == "main_user") return pat.main_user ? pat.main_user.name : ""
            if (key == "saigai") return (
                pat.saigai.priority && pat.saigai.priority != Model.Patient.SaigaiPriority.None ?
                    pat.saigai.priority : "優先度Z"
            )
            if (key == "shijisho"){
                if(pat.docs && pat.docs.shijisho){
                    const shijishoDateTo = pat.docs.shijisho[0].content.date_to
                    return new Date(shijishoDateTo).getTime()
                }
                if(query.sortDir === 1) return Infinity
                return -Infinity
            }
            if (key == "tokushiji"){
                if(pat.docs && pat.docs.tokushiji){
                    const tokushijiDateTo = pat.docs.tokushiji[0].content.date_to
                    return new Date(tokushijiDateTo).getTime()
                }
                if(query.sortDir === 1) return Infinity
                return -Infinity
            }
            if(key == "hoken_kigen_to"){
                if(pat.docs && pat.docs.hoken_iryo){
                    return new Date(pat.docs.hoken_iryo[0].content.kigen_to).getTime()
                }
                if(pat.docs && pat.docs.hoken_kaigo){
                    return new Date(pat.docs.hoken_kaigo[0].content.kigen_to).getTime()
                }
                if(pat.docs && pat.docs.hoken_gendo){
                    return new Date(pat.docs.hoken_gendo[0].content.kigen_to).getTime()
                }
                if(pat.docs && pat.docs.hoken_kouhi){
                    return new Date(pat.docs.hoken_kouhi[0].content.kigen_to).getTime()
                }
                if(query.sortDir === 1) return Infinity
                return -Infinity
            }
            if (key == "hoken_type") {
                const hoken_type = pat.hoken.hoken_type
                // この順で並び替える
                const HOKEN_TYPE_SORT_VALUE = ["医療", "介護", "介護予防", "精神"]
                const index = HOKEN_TYPE_SORT_VALUE.findIndex((v) => v === hoken_type)
                if(index === -1) return 99
                return index
            }
            return pat[key]
        }

        let patients = this.state.patients.filter(x => {
            let isOk = true;
            const isMain = (x.main_user != null && this.state.me.id == x.main_user.id)
            const isSub1 = (x.sub_user1 != null && this.state.me.id == x.sub_user1.id)
            const isSub2 = (x.sub_user2 != null && this.state.me.id == x.sub_user2.id)
            if (query.onlyMe && !isMain && !isSub1 && !isSub2) isOk = false;

            if (query.name != "") {
                if (notNameMatch(query.name, x.name, x.name_kana) &&
                    notTantoMatch(query.name, x) &&
                    notAddMatch(query.name, x.env.address1 + x.env.address2 + x.env.address3) &&
                    notTelMatch(query.name, x.tel_home) && notTelMatch(query.name, x.tel_cell)) {
                    isOk = false;
                }
            }
            if (!query.showDisabled) {
                if (x.status == "入院中") isOk = false
                else if (x.status == "休止中") isOk = false
                else if (x.status == "終了") isOk = false
            }
            return isOk;
        }).sort((a, b) => {
            const key = query.sortKey
            const dir = query.sortDir
            const namediff = (a['name_kana'] > b['name_kana'] ? 1 : a['name_kana'] < b['name_kana'] ? -1 : 0)
            const av = getSortValue(a, key)
            const bv = getSortValue(b, key)
            if (av == bv) return namediff
            if (av > bv) return dir
            return -dir
        })

        const sortMark = (key: string) => {
            if (key == query.sortKey) {
                return query.sortDir > 0 ? "▲" : "▼"
            }
            return ""
        }

        const statusMark = (key: string) => {
            let col = "green"
            if (key == "利用中") col = "green"
            else if (key == "入院中" || key == "休止中") col = "goldenrod"
            else if (key == "終了") col = "gray"
            return <span style={{ color: col, fontSize: "smaller" }}>●</span>
        }

        const filteredAndSortedPatients = (() => {
            if(tabIndex === 0){
                return patients.filter(x => x.is_shinki == false)
            }else if(tabIndex === 1){
                return patients.filter(x => x.is_shinki == true).sort((a, b) => {
                    if (a.is_canseled && !b.is_canseled) return +1
                    if (!a.is_canseled && b.is_canseled) return -1
                    if (a.updated_at < b.updated_at) return +1
                    if (a.updated_at > b.updated_at) return -1
                    return 0
                });
            }else if(tabIndex === 2){
                return this.state.patientsJimu.filter(x => x.is_shinki == false && patients.findIndex(y => y.id == x.id) >= 0).sort((a, b) => {
                    const key = query.sortKey
                    const dir = query.sortDir
                    const namediff = (a['name_kana'] > b['name_kana'] ? 1 : a['name_kana'] < b['name_kana'] ? -1 : 0)
                    const av = getSortValue(a, key)
                    const bv = getSortValue(b, key)
                    if (av == bv) return namediff
                    if (av > bv) return dir
                    return -dir
                });
            }
            return patients
        })()

        const isTokubetuKanri1 = (patient: Model.Patient.Patient): boolean => {
            const kigus = patient.saigai.kigus;
            const is_tokubetu_kanri_1 = (
                kigus.akuseishuyou || kigus.kikansekkai ||
                kigus.kikan_kanyure || kigus.ryuchi_katetel
            )
            return is_tokubetu_kanri_1
        }

        const isTokubetuKanri2 = (patient: Model.Patient.Patient): boolean => {
            const kigus = patient.saigai.kigus;
            const is_tokubetu_kanri_2 = !isTokubetuKanri1(patient) && (
                kigus.jikohukumaku || kigus.ketsueki_touseki ||
                kigus.sanso_ryouhou || kigus.chushin_joumyakuu ||
                kigus.seibun_eiyou || kigus.jiko_dounyou ||
                kigus.jizoku_youatsu || kigus.jiko_toutsuu ||
                kigus.hai_kouatsu || kigus.jinkou_koumon ||
                kigus.jokuso || kigus.tenteki ||
                kigus.jinkou_kokyu
            )
            return is_tokubetu_kanri_2
        }

        const csvData: string | null = (() => {
            if(tabIndex == 0){
                const headers = (() => {
                    const tmpArr = ["名前"]
                    if(query.name != "" || true) tmpArr.push("電話番号")
                    tmpArr.push("担当者")
                    if(this.state.query.showSaigai) tmpArr.push("災優")
                    if(this.state.query.showCreatedAt) tmpArr.push("登録日")
                    tmpArr.push("住所")

                    return tmpArr
                })()
                let result = headers.join(",") + '\n'

                filteredAndSortedPatients.forEach(patient => {
                    const row: (string | number)[] = []
                    headers.forEach((header) => {
                        switch(header){
                            case "名前":
                                row.push(patient.name)
                                break;
                            case "電話番号":
                                row.push(`${patient.tel_home} ${patient.tel_cell}`)
                                break;
                            case "担当者":
                                row.push(patient.main_user ? patient.main_user.name : "")
                                break;
                            case "住所":
                                row.push(`${patient.env.address1 + patient.env.address2} ${patient.env.address3}`)
                                break;
                            case "災優":
                                row.push(
                                    `${patient.saigai ?
                                    (patient.saigai.priority && patient.saigai.priority.length >= 3 ? patient.saigai.priority[3] : "-")
                                    : "-"}${patient.saigai.comment != "" ? ":" + patient.saigai.comment : ""}`
                                )
                                break;
                            case "登録日":
                                row.push(DateUtil.toSlashDateStringShort(patient.created_at))
                                break;
                            case "住所":
                                row.push(`${patient.env.address1} ${patient.env.address2} ${patient.env.address3}`)
                                break;
                            default:
                                throw new Error("never")
                        }
                    })
                    const cleanedRow = row.map(item => 
                        typeof item === "string" ? item.replace(/\r?\n/g, " ") : item
                    );
                    result += cleanedRow.join(",") + "\n"
                })
                return result
            }
            if(tabIndex === 1){
                const headers = ["名前", "登録日", "年齢", "市区町村", "次のアクション", "住所"]
                let result = headers.join(",") + '\n'
                filteredAndSortedPatients.forEach((patient) => {
                    const row: (string | number)[] = []
                    headers.forEach((header) => {
                        switch(header){
                            case "名前":
                                row.push(patient.name)
                                break;
                            case "登録日":
                                row.push(DateUtil.toSlashDateStringShort(patient.created_at))
                                break;
                            case "年齢":
                                row.push(patient.ukeire_check.age)
                                break;
                            case "市区町村":
                                row.push(patient.env.address2)
                                break;
                            case "次のアクション":
                                row.push(patient.ukeire_check.next_action)
                                break;
                            case "住所":
                                row.push(`${patient.env.address1} ${patient.env.address2} ${patient.env.address3}`)
                                break;
                            default:
                                throw new Error("never")
                        }
                    })
                    const cleanedRow = row.map(item => 
                        typeof item === "string" ? item.replace(/\r?\n/g, " ") : item
                    );
                    result += cleanedRow.join(",") + "\n"
                })
                return result
            }
            if(tabIndex === 2){
                const headers = ["名前", "年齢", "電話番号", "基本となる保険", "指示書期間", "特指示期間", "GAF作成日", "褥瘡対策作成日", "特管理加算１", "特管理加算２", "保険期限", "情提供作成日", "疾患分類", "備考", "住所"]

                let result = headers.join(",") + '\n'
                filteredAndSortedPatients.forEach((patient) => {
                    const row: (string | number)[] = []
                    headers.forEach((header) => {
                        switch(header){
                            case "名前":
                                row.push(patient.name)
                                break;
                            case "年齢":
                                row.push(DateUtil.getAge(patient.birthday))
                                break;
                            case "年齢":
                                row.push(patient.ukeire_check.age)
                                break;
                            case "電話番号":
                                row.push(`${patient.tel_home} ${patient.tel_cell}`)
                                break;
                            case "基本となる保険":
                                row.push(patient.hoken.hoken_type ? patient.hoken.hoken_type : "")
                                break;
                            case "指示書期間":
                                row.push(
                                    patient.docs && patient.docs.shijisho
                                        ? `${patient.docs.shijisho[0].content.date_from} ~ ${patient.docs.shijisho[0].content.date_to}`
                                        : ""
                                )
                                break;
                            case "特指示期間":
                                row.push(
                                    patient.docs && patient.docs.tokushiji
                                        ? `${patient.docs.tokushiji[0].content.date_from} ~ ${patient.docs.tokushiji[0].content.date_to}`
                                        : ""
                                )
                                break;
                            case "GAF作成日":
                                row.push(
                                    patient.docs && patient.docs.gaf
                                        ? `${patient.docs.gaf[0].content.values.create_date}`
                                        : ""
                                )
                                break;
                            case "褥瘡対策作成日":
                                row.push(
                                    patient.docs && patient.docs.jokuso
                                        ? `${patient.docs.jokuso[0].content.values.create_date}`
                                        : ""
                                )
                                break;
                            case "特管理加算１":
                                row.push(isTokubetuKanri1(patient) ? "●" : "")
                                break;
                            case "特管理加算２":
                                row.push(isTokubetuKanri2(patient) ? "●" : "")
                                break;
                            case "保険期限":
                                if(patient.docs === null) {
                                    row.push("")
                                    break
                                }
                                if(patient.docs.hoken_iryo){
                                    row.push(`医:${patient.docs.hoken_iryo[0].content.kigen_to}`)
                                }else if(patient.docs.hoken_kaigo){
                                    row.push(`介:${patient.docs.hoken_kaigo[0].content.kigen_to}`)
                                }else if(patient.docs.hoken_gendo){
                                    row.push(`限:${patient.docs.hoken_gendo[0].content.kigen_to}`)
                                }else if(patient.docs.hoken_kouhi){
                                    row.push(`公:${patient.docs.hoken_kouhi[0].content.kigen_to}`)
                                }
                                break;
                            case "情提供作成日":
                                row.push(
                                    patient.docs && patient.docs.jouho_teikyo
                                        ? `${patient.docs.jouho_teikyo[0].content.values.create_date}`
                                        : ""
                                )
                                break;
                            case "疾患分類":
                                row.push(patient.basic.shikkan_type)
                                break;
                            case "備考":
                                row.push(patient.basic.jimu_tokki)
                                break;
                            case "住所":
                                row.push(`${patient.env.address1} ${patient.env.address2} ${patient.env.address3}`)
                                break;
                            default:
                                throw new Error("never")
                        }
                    })
                    const cleanedRow = row.map(item => 
                        typeof item === "string" ? item.replace(/\r?\n/g, " ") : item
                    );
                    result += cleanedRow.join(",") + "\n"
                })
                return result
            }


            return null
        })()

        const onCsvExportButtonClick = () => {
            const str = Encoding.stringToCode(csvData);
            const convert = Encoding.convert(str, 'sjis', 'unicode');
            const u8a = new Uint8Array(convert);

            const blob = new Blob([u8a], { type: 'text/csv;charset=Shift_JIS;' });
            saveAs(blob, `利用者一覧.csv`);
        }

        return (
            <div className={classes.root}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Button color="default" variant="outlined" style={{ margin: "5px" }}
                        onClick={(e) => history.push(`/patients/shinki_irai/0`)}>
                        新規依頼
                    </Button>
                    {csvData && <Button type="button" variant='text' onClick={onCsvExportButtonClick} style={{}}>CSV↓(Sjis)</Button>}
                </div>
                <div style={{ display: "block", width: "100%" }}>
                    <StandardTable title=''>
                        <Grid container={true} spacing={0}>
                            <Grid item={true} xs={12} md={12} lg={12}>
                                <Col2Table body={element_l} />
                            </Grid>
                        </Grid>
                    </StandardTable>
                </div>
                <Paper square>
                    <Tabs value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(e, v) => this.tabChange(e, v)}
                        aria-label="tab">
                        <Tab label="利用者" />
                        <Tab label="新規依頼" />
                        <Tab label="事務用" />
                        <Tab label="スレッド" />
                    </Tabs>
                </Paper>
                <br />
                {
                    loading ? <Loading /> :
                        <div>
                            {tabIndex == 0 ?
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell style={{ textAlign: "center", padding: "0px" }}>☆</CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "center", padding: "0px" }}></CustomTableCell>
                                            <CustomTableCell style={{ textAlign: "left", padding: "10px", cursor: "pointer" }} onClick={(e) => this.setSortKey("name_kana")}>名前{sortMark("name_kana")}</CustomTableCell>
                                            {
                                                query.name != "" || true ?
                                                    <CustomTableCell key="tel_col" style={{ textAlign: "left", padding: "10px", cursor: "pointer" }} onClick={(e) => this.setSortKey("tel")}>電話番号{sortMark("tel")}</CustomTableCell>
                                                    : null
                                            }
                                            <CustomTableCell align="left" style={{ cursor: "pointer" }} onClick={(e) => this.setSortKey("main_user")}>担当者{sortMark("main_user")}</CustomTableCell>
                                            {
                                                query.name != "" ?
                                                    <CustomTableCell align="left" style={{ cursor: "pointer" }} onClick={(e) => this.setSortKey("address")}>住所{sortMark("address")}</CustomTableCell>
                                                    : null
                                            }
                                            {this.state.query.showSaigai ? <CustomTableCell align="left" style={{ cursor: "pointer" }} onClick={(e) => this.setSortKey("saigai")}>災優{sortMark("saigai")}</CustomTableCell> : null}
                                            {this.state.query.showCreatedAt ? <CustomTableCell align="left" style={{ cursor: "pointer" }} onClick={(e) => this.setSortKey("created_at")}>登録日{sortMark("created_at")}</CustomTableCell> : null}
                                            <CustomTableCell align="right"></CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filteredAndSortedPatients.map(patient => {
                                                const isFavo = this.isFavo(patient.id)
                                                return <TableRow key={patient.id} className={classes.row}
                                                    onClick={(e) => history.push(`/patients/${patient.id}`)}>
                                                    <CustomTableCell style={{ textAlign: "center", padding: "1px", width: "fit-content" }} component="th" scope="row"
                                                        onClick={(e) => {
                                                            this.setFavo(patient.id, patient.name, !isFavo)
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                        }}
                                                    >{isFavo ? "★" : "☆"}</CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "center", paddingRight: "3px" }} component="th" scope="row">{statusMark(patient.status)}</CustomTableCell>
                                                    <CustomTableCell style={{ textAlign: "left", padding: "5px", minWidth: "5rem" }} component="th" scope="row">{patient.name}</CustomTableCell>
                                                    {
                                                        query.name != "" || true ?
                                                            <CustomTableCell style={{ textAlign: "left", padding: "2px" }} component="th" scope="row">{patient.tel_home}<br />{patient.tel_cell}</CustomTableCell>
                                                            : null
                                                    }
                                                    <CustomTableCell align="left">{patient.main_user ? patient.main_user.name : ""}</CustomTableCell>
                                                    {
                                                        query.name != "" ?
                                                            <CustomTableCell style={{ textAlign: "left", padding: "2px" }} component="th" scope="row">{patient.env.address1 + patient.env.address2}<br />{patient.env.address3}</CustomTableCell>
                                                            : null
                                                    }
                                                    {this.state.query.showSaigai ? <CustomTableCell align="left">{patient.saigai ?
                                                        (patient.saigai.priority && patient.saigai.priority.length >= 3 ? patient.saigai.priority[3] : "-")
                                                        : "-"}<span style={{ whiteSpace: "pre-line" }}>{patient.saigai.comment != "" ? ":" + patient.saigai.comment : ""}</span></CustomTableCell> : null}
                                                    {this.state.query.showCreatedAt ? <CustomTableCell align="center">{DateUtil.toSlashDateStringShort(patient.created_at)}</CustomTableCell> : null}
                                                    <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                : null
                            }
                            {tabIndex == 1 ?
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell style={{ textAlign: "left", padding: "10px" }}>名前</CustomTableCell>
                                            <CustomTableCell align="left">登録日</CustomTableCell>
                                            <CustomTableCell align="left">年齢</CustomTableCell>
                                            <CustomTableCell align="left">市区町村</CustomTableCell>
                                            <CustomTableCell align="left">次のアクション</CustomTableCell>
                                            <CustomTableCell align="right"></CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            filteredAndSortedPatients.map(patient => {
                                                return <TableRow key={patient.id} className={classes.row}
                                                    style={{ backgroundColor: (patient.is_canseled ? "lightgray" : "inherit") }}
                                                    onClick={(e) => history.push(`/patients/shinki_irai/${patient.id}`)}>
                                                    <CustomTableCell style={{ textAlign: "left", padding: "2px" }} component="th" scope="row">{patient.name}</CustomTableCell>
                                                    <CustomTableCell align="left">{DateUtil.toSlashDateStringShort(patient.created_at)}</CustomTableCell>
                                                    <CustomTableCell align="left">{patient.ukeire_check.age}歳</CustomTableCell>
                                                    <CustomTableCell align="left">{patient.env.address2}</CustomTableCell>
                                                    <CustomTableCell align="left">{patient.ukeire_check.next_action}</CustomTableCell>
                                                    <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                : null
                            }
                            {tabIndex == 2 ?
                                this.state.loadingJimu ? <div style={{ textAlign: "center", width: "100%" }}>
                                    少し時間がかかります
                                    <Loading />
                                </div> :
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell style={{ textAlign: "left", padding: "10px", cursor: "pointer" }} onClick={(e) => this.setSortKey("name_kana")}>名前{sortMark("name_kana")}</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }}>年齢{sortMark("age")}</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }}>電話番号{sortMark("tel")}</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }} onClick={(e) => this.setSortKey("hoken_type")}>基本となる保険{sortMark("hoken_type")}</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }} onClick={(e) => this.setSortKey("shijisho")}>指示書期間{sortMark("shijisho")}</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }} onClick={(e) => this.setSortKey("tokushiji")}>特指示期間{sortMark("tokushiji")}</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }}>GAF<br />作成日{sortMark("gaf")}</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }}>褥瘡対策<br />作成日</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }}>特管理<br />加算１</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }}>特管理<br />加算２</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }} onClick={(e) => this.setSortKey("hoken_kigen_to")}>保険期限{sortMark("hoken_kigen_to")}</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }}>情提供<br />作成日</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }}>疾患<br />分類</CustomTableCell>
                                                <CustomTableCell align="left" style={{ cursor: "pointer" }}>備考</CustomTableCell>
                                                <CustomTableCell align="right"></CustomTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                filteredAndSortedPatients.map(patient => {
                                                    return <TableRow key={patient.id} className={classes.row}
                                                        onClick={(e) => history.push(`/patients/${patient.id}`)}>
                                                        <CustomTableCell style={{ textAlign: "left", padding: "5px" }} component="th" scope="row">{patient.name}</CustomTableCell>
                                                        <CustomTableCell align="left">{DateUtil.getAge(patient.birthday)}</CustomTableCell>
                                                        <CustomTableCell align="left">{patient.tel_home}<br />{patient.tel_cell}</CustomTableCell>
                                                        <CustomTableCell align="left">{patient.hoken.hoken_type ? patient.hoken.hoken_type : ""}</CustomTableCell>
                                                        <CustomTableCell align="left">{
                                                            patient.docs && patient.docs.shijisho ? <span>
                                                                {patient.docs.shijisho[0].content.date_from} 〜 {patient.docs.shijisho[0].content.date_to}
                                                            </span>
                                                                : null
                                                        }
                                                        </CustomTableCell>
                                                        <CustomTableCell align="left">{
                                                            patient.docs && patient.docs.tokushiji ? <span>
                                                                {patient.docs.tokushiji[0].content.date_from} 〜 {patient.docs.tokushiji[0].content.date_to}
                                                            </span>
                                                                : null
                                                        }
                                                        </CustomTableCell>
                                                        <CustomTableCell align="left" style={{ cursor: "pointer" }}>{
                                                            patient.docs && patient.docs.gaf ? <span>
                                                                {patient.docs.gaf[0].content.values.create_date}
                                                            </span>
                                                                : null
                                                        }</CustomTableCell>
                                                        <CustomTableCell align="left" style={{ cursor: "pointer" }}>
                                                            {
                                                                patient.docs && patient.docs.jokuso ? <span>
                                                                    {patient.docs.jokuso[0].content.values.create_date}
                                                                </span>
                                                                    : null
                                                            }</CustomTableCell>
                                                        <CustomTableCell align="center" style={{ cursor: "pointer" }}>{isTokubetuKanri1(patient) ? "●" : ""}</CustomTableCell>
                                                        <CustomTableCell align="center" style={{ cursor: "pointer" }}>{isTokubetuKanri2(patient) ? "●" : ""}</CustomTableCell>
                                                        <CustomTableCell align="left">
                                                            {patient.docs && patient.docs.hoken_iryo ? <div>医:{patient.docs.hoken_iryo[0].content.kigen_to}</div> : null}
                                                            {patient.docs && patient.docs.hoken_kaigo ? <div>介:{patient.docs.hoken_kaigo[0].content.kigen_to}</div> : null}
                                                            {patient.docs && patient.docs.hoken_gendo ? <div>限:{patient.docs.hoken_gendo[0].content.kigen_to}</div> : null}
                                                            {patient.docs && patient.docs.hoken_kouhi ? <div>公:{patient.docs.hoken_kouhi[0].content.kigen_to}</div> : null}
                                                        </CustomTableCell>
                                                        <CustomTableCell align="left">{
                                                            patient.docs && patient.docs.jouho_teikyo ? <span>
                                                                {patient.docs.jouho_teikyo[0].content.values.create_date}
                                                            </span>
                                                                : null
                                                        }</CustomTableCell>
                                                        <CustomTableCell align="left">{patient.basic.shikkan_type}</CustomTableCell>
                                                        <CustomTableCell align="left">{patient.basic.jimu_tokki}</CustomTableCell>
                                                        <CustomTableCell align="right" className={classes.itemIcon}><Icons.ArrowForwardIos /></CustomTableCell>
                                                    </TableRow>
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                : null
                            }
                            {tabIndex == 3 ?
                                <TalkList showButton={true} branchId={this.state.branch_id} />
                                : null
                            }
                        </div>

                }
            </div>
        );
    }
}

export default withPageProps(Styles.withStyles(styles)(PatientListPage))
